import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import { Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { NotFound } from "./components";

import { Payments } from "./pages";

const ConfigureRoute = ({ component: Component, ...rest }) => {
  if (process.env.NODE_ENV === "production") {
    return <ApmRoute {...rest} component={Component} />;
  } else {
    return <Route {...rest} component={Component} />;
  }
};

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#362F59",
          borderRadius: 3,
        },
      }}
    >
      <Router>
        <Switch>
          <ConfigureRoute
            exact
            path="/sessions/:id/payment"
            render={(props) => {
              return <Payments {...props} />;
            }}
          />
          <ConfigureRoute path="/" component={NotFound} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default App;
