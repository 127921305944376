import React from "react";
import { Row, Col, Empty } from "antd";

const Expired = () => {
  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col span={24}>
        <Empty
          image={"/assets/images/nodata.svg"}
          imageStyle={{
            height: 200,
          }}
          description="Payment link expired"
        />
      </Col>
    </Row>
  );
};

export default Expired;
