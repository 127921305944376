import React from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
const Loading = () => {
  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        top: "50%",
        right: "50%",
        marginTop: -30,
        marginRight: -15,
      }}
    >
      <Loading3QuartersOutlined spin style={{ fontSize: 25 }} />
    </div>
  );
};

export default Loading;
