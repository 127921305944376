import React from "react";
import { Row, Col, Empty, Button } from "antd";

const Success = () => {
  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col span={24}>
        <Empty
          image={"/assets/images/payment_success.svg"}
          imageStyle={{
            height: 200,
          }}
          description="Payment was successful"
        />
      </Col>
    </Row>
  );
};

export default Success;
