import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { Row, Col, Form, Input, Button } from "antd";

import { PaymentSuccess } from "../../components";

const PaymentForm = ({ session }) => {
  const clientSecret = session?.meta_data?.client_secret;

  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [errorText, setErrorText] = useState("");

  const onFinish = async (values) => {
    setPaymentProcessing(true);
    var cardNumberElement = elements.getElement(CardNumberElement);
    if (stripe) {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              email: values.email,
            },
          },
        }
      );

      if (error) {
        setErrorText(error.message);
      } else {
        setPaymentDone(true);
      }
      setPaymentProcessing(false);
    } else {
      setPaymentProcessing(false);
    }
  };

  useEffect(() => {
    if (stripe && session?.meta_data?.client_secret) {
      const _paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: session?.payment?.currency?.toLowerCase(),
        total: {
          label: "",
          amount: session?.payment?.authorized_amount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      _paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);
        } else {
          console.log("Payment Request Error");
        }
      });
    }
  }, [stripe, session]);

  useEffect(() => {
    if (clientSecret && paymentRequest) {
      paymentRequest.on("paymentmethod", async (ev) => {
        setPaymentProcessing(true);
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          setErrorText(confirmError.message);
          setPaymentProcessing(false);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11" instead
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              setErrorText("Please select a different payment method");
              setPaymentProcessing(false);
            } else {
              setPaymentProcessing(false);
              setPaymentDone(true);
            }
          } else {
            // The payment has succeeded.
            setPaymentProcessing(false);
            setPaymentDone(true);
          }
        }
      });
    }
  }, [paymentRequest, session]);

  if (paymentDone) {
    return <PaymentSuccess />;
  }

  return (
    <>
      {paymentRequest && false ? (
        <div style={{ margin: "0 28px" }}>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: "dark",
                  height: "40px",
                },
              },
            }}
          />
        </div>
      ) : null}

      <Form layout="vertical" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: "email" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Card Information">
              <div
                style={{
                  marginTop: 0,
                  borderRadius: 5,
                  border: "1px solid #DDD",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    padding: 16,
                    width: "100%",
                  }}
                >
                  <CardNumberElement
                    {...createOptions()}
                    id="stripe-credit-card-number"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      padding: 16,
                      flex: 1,
                      borderRight: "1px solid #DDD",
                      borderTop: "1px solid #DDD",
                    }}
                  >
                    <CardExpiryElement
                      {...createOptions()}
                      id="credit-card-expiry-date"
                    />
                  </div>
                  <div
                    style={{
                      padding: 16,
                      flex: 1,
                      borderTop: "1px solid #DDD",
                    }}
                  >
                    <CardCvcElement {...createOptions()} id="credit-card-cvc" />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
          {errorText ? (
            <Col span={24} style={{ color: "#ff4d4f", paddingBottom: 20 }}>
              <span>* {errorText}</span>
            </Col>
          ) : null}
          <Col span={24}>
            <Button
              disabled={paymentProcessing}
              htmlType="submit"
              block
              size="large"
              type="primary"
              loading={paymentProcessing}
            >
              {"Pay "}
              {session?.payment?.authorized_amount}
              {` ${session.payment?.currency}`.toLocaleUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const createOptions = () => {
  return {
    style: {
      base: {
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
        width: "100%",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

export default PaymentForm;
