import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Empty, Row, Col } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Expired, Loading, NotFound } from "../../components";
import PaymentForm from "../Payment/PaymentForm";

const BASE_URL = process.env.REACT_APP_API_URL;

const Payments = () => {
  const { id } = useParams();
  const [expiryInSeconds, setExpiryInSeconds] = useState(0);
  const [session, setSession] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/v1.1/public/sessions/${id}/payment`
      );

      const data = response.data;
      if (data && data?.ok) {
        setSession(data.result);
        const _session = data.result;
        if (_session.expiredAt) {
          const expiry = moment(_session.expiredAt)
            .utc()
            .diff(moment().utc(), "seconds");
          setExpiryInSeconds(expiry);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const initiateTimer = () => {
    window.timerInterval = setInterval(() => {
      setExpiryInSeconds((v) => v - 1);
    }, 1000);
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (expiryInSeconds <= 0 && session?._id) {
      clearInterval(window.timerInterval);
    } else {
      initiateTimer();
    }
    return () => {
      clearInterval(window.timerInterval);
    };
  }, [expiryInSeconds]);

  if (loading) {
    return <Loading />;
  }

  if (!session?._id) {
    return <NotFound />;
  }

  if (expiryInSeconds <= 0) {
    return <Expired />;
  }
  const stripePromise = loadStripe(
    session?.payment?.test_mode
      ? process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
      : process.env.REACT_APP_PRODUCTION_STRIPE_KEY,
    {
      apiVersion: "2020-08-27",
      stripeAccount: session?.payment?.stripe_connected_account_id,
    }
  );

  const minutes = parseInt(expiryInSeconds / 60);
  const seconds = expiryInSeconds % 60;
  const address = session?.location?.address || {};
  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col span={24}>
        <Row
          gutter={[10, 10]}
          style={{ paddingRight: "2rem", paddingLeft: "2rem" }}
        >
          <Col span={24}>
            <Empty
              image={"/assets/images/pay.svg"}
              imageStyle={{
                height: "15rem",
                width: "auto",
              }}
              description=""
            />
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
              color: "#362f59",
              fontSize: 16,
            }}
          >
            <span>
              Start your session (
              <b>
                {address.city}, {address.streetAndNumber}
              </b>
              ,&nbsp;
              <b>#{session?.chargestation?.endpoint}</b>
              &nbsp;station)
            </span>
          </Col>
          <Col span={24}>
            <Elements stripe={stripePromise}>
              <PaymentForm session={session} />
            </Elements>
          </Col>
          <Col
            span={24}
            style={{
              paddingTop: 10,
              fontSize: 14,
              fontFamily: "monospace",
              color: "#adadad",
            }}
          >
            <span>Expires in: </span>
            <span>
              {minutes < 10 ? "0" : ""}
              {minutes}
            </span>
            <span>:</span>
            <span>
              {seconds < 10 ? "0" : ""}
              {seconds}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Payments;
