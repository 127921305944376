import React from "react";
import { Empty, Row, Col } from "antd";
const NotFound = () => {
  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col span={24}>
        <Empty
          image={"/assets/images/notfound.svg"}
          imageStyle={{
            height: 200,
          }}
          description="Session not found"
        />
      </Col>
    </Row>
  );
};

export default NotFound;
